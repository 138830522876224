<template>
  <div>
    <van-radio-group v-model="radio" @change="changeHandle">
      <div v-for="(item,index) in dataList" :key="index" :class="{'is-invalid-cupones':(item.is_use+'' === '3' || item.is_use+'' === '2')}" class="list-item" @click="handleClick(item)">
        <div v-if="item.is_use+'' === '3' || item.is_use+'' === '2'" class="ticket-status"><span>Sudah tidak berlaku</span></div>
        <div class="type">{{ item.use_ticket_source }}</div>
        <div class="price">Rp<span>{{ moneyDivision(item.use_coupon_amount) }}</span></div>
        <div class="date">
          <span>{{ item.ticket_msg }}</span>
          <!-- <span>Rp9.000</span> -->
        </div>
        <van-radio v-if="isSelect" class="check-riado" disabled :name="item[isSelectKey]">
          <template #icon="props">
            <span :class="{'is-check':props.checked}" class="check-icon" />
          </template>
        </van-radio>
      </div>
    </van-radio-group>
  </div>
</template>

<script>
import { moneyDivision } from '@/utils/index'
export default {
  props: {
    isSelect: {
      type: [Boolean, String, Number],
      default: false
    },
    isSelectKey: {
      type: [String, Number],
      default: () => {
        return 'use_coupon_id'
      }
    },
    buyTicket: {
      type: Boolean,
      default: false
    },
    ticketList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      moneyDivision,
      dataList: [],
      radio: '',
      cancleData: ''
    }
  },
  computed: {
    ticketSource() {
      return function(type) {
        return type + '' === '1' ? this.$t('navbar.buytext') : this.$t('navbar.songtext')
      }
    }
  },
  watch: {
    ticketList() {
      this.dataList = this.ticketList
    }
  },
  methods: {
    handleClick(item) {
      if (this.radio === item[this.isSelectKey]) {
        this.cancleData = item[this.isSelectKey]
        this.radio = ''
        return
      }
      this.radio = item[this.isSelectKey]
    },
    changeHandle() {
      let item = this.dataList.find(item => { return item[this.isSelectKey] + '' === this.radio + '' })
      if (!this.radio) item = ''
      // {
      //   item = this.dataList.find(item => { return item[this.isSelectKey] + '' === this.cancleData + '' })
      //   item.cancle = true
      // }
      this.$emit('chooseCoupon', item)
    },
    defaultSelect() {
      if (this.$store.state.andirodData) {
        const andirodData = JSON.parse(this.$store.state.andirodData)
        const idArry = andirodData.use_coupon_id.split(',')

        idArry.forEach(id => {
          const item = this.dataList.find(item => { return item[this.isSelectKey] + '' === id + '' })
          if (item) this.radio = item[this.isSelectKey]
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.list-item{
  position: relative;
  height: 304px;
  margin-bottom: 18px;
  background: url('~@/assets/images/userCenter/cupones-bg-icon.png') no-repeat center center;
  background-size: 100% 100%;
  .type{
    font-size: 24px;
    font-family: Roboto-Medium, Roboto;
    font-weight: 500;
    color: #FBFCFF;
    line-height: 28px;
    text-align: center;
    padding-top: 38px;
    padding-bottom: 20px;
  }
  .price{
    font-size: 60px;
    font-family: Roboto-Bold, Roboto;
    font-weight: bold;
    color: #FFFFFF;
    text-align: center;
    span{
      font-size: 60px;
      font-family: Roboto-Bold, Roboto;
      font-weight: bold;
      color: #FAD051;
    }
  }
  .date{
    font-size: 28px;
    font-family: Roboto-Medium, Roboto;
    font-weight: 500;
    color: #4687F6;
    line-height: 30px;
    position: absolute;
    left: 40px;
    right: 40px;
    bottom: 28px;
    display: flex;
    justify-content: space-between;
  }
}
.left-data{
  position: relative;
  padding-top: 68px;
}
.right-data{
  display: flex;
  align-items: center;
  span{
    font-size: 24px;
    font-family: Roboto-Regular, Roboto;
    font-weight: 400;
    color: #002424;
    line-height: 28px;
    padding-left: 30px;
    padding-right: 52px;
  }
}
.left-data::after{
  content: '';
  position: absolute;
  height: 68px;
  border-right: 3px dashed #F1F1F1FF;
  right: 0;
  top:50%;
  margin-top:-18px;
}
.check-riado{
  position:absolute;
  top: 10px;
  right: 10px;
}
:deep(.van-radio){
  width: 50px;
  height:50px;
}
:deep(.van-radio__icon){
  display:block;
  height:100%;
}
.check-icon{
  display: block;
  width: 48px;
  height: 48px;
   background: url('~@/assets/images/userCenter/no-check-icon.png') no-repeat center center;
  background-size:100%
}
.is-check{
  border: none;
  background: url('~@/assets/images/userCenter/is-check-icon.png') no-repeat center center;
  background-size:100%
}

.come-from{
  font-size: 24px;
  font-family: Roboto-Bold, Roboto;
  font-weight: bold;
  color: #FC5D5D;
  line-height: 28px;
  padding-bottom: 22px;
  padding-left: 94px;
}
.cupones-price{
  font-size: 48px;
  font-family: Roboto-Bold, Roboto;
  font-weight: bold;
  color: #FC5D5D;
  line-height: 56px;
  padding-left: 94px;
}
.is-invalid-cupones{
  background: url('~@/assets/images/userCenter/sx-coupones-bg-icon.png') no-repeat center center;
  background-size: 100%;
  margin-bottom: 132px;
  .date{
    opacity: 0.6;
  }
  .ticket-status{
    position: absolute;
    display: flex;
    left: 0;
    right: 0;
    justify-content: center;
    top: -94px;
    span{
      background: rgba(61,92,255,0.1);
      border-radius: 12px;
      font-size: 24px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #3D5CFF;
      padding: 20px;
    }
  }
  .come-from,.cupones-price,.right-data{
    color: #999999FF!important;
  }
}
.is-invalid-cupones.list-item::after{
  content:'';
  position:absolute;
  width: 104px;
  height: 104px;
  top: 20px;
  right: 20px;
  // background: url('~@/assets/images/userCenter/invalid-cupones-bage.png') no-repeat center center;
  background-size: 100%;
}

.cupones-num{
  font-size: 28px;
  font-family: Roboto-Bold, Roboto;
  font-weight: bold;
  color: #002424;
  position: relative;
  padding-left: 160px;
  margin-top: -18px;
  padding-bottom: 24px;
  display: flex;
  align-items: center;
}
.cupones-num::before{
  content:'';
  position:absolute;
  width: 48px;
  height: 38px;
  left: 94px;
  // background: url('~@/assets/images/userCenter/buy-coupons-icon.png') no-repeat center center;
  background-size: 100%;
}
.buy-remark{
  font-size: 24px;
  font-family: Roboto-Regular, Roboto;
  font-weight: 400;
  color: #FC5D5D;
  padding-bottom: 2px;
  padding-left: 94px;
}

.buy-ticket-right{
  padding-left: 30px;
  padding-right: 52px;
  font-size: 24px;
  font-family: Roboto-Regular, Roboto;
  font-weight: 400;
  color: #002424;
  padding-top:14px;
  p{
    padding-bottom: 6px;
  }
  .price{
    font-size: 28px;
    font-family: Roboto-Bold, Roboto;
    font-weight: bold;
    color: #002424;
  }
}

</style>
