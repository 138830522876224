<template>
  <base-template
    :description="$t('userCenter.noCoupons')"
    :empty-image="emptyImage"
    :is-empty="isEmpty"
  >
    <div :class="{'has-notice-text':noticeText}" class="top-area">
      <marquee v-if="noticeText" class="notice-box" behavior="scroll">{{ noticeText }}</marquee>
      <div class="remark-box">
        <div class="remark-top">
          <span class="remark">{{ $t('userCenter.addLessText') }}</span>
          <span class="use-rule" @click="goRule">{{ $t('userCenter.useRule') }}</span>
        </div>
        <div class="price">Rp {{ moneyDivision(lessPrice) }}</div>
      </div>
    </div>
    <van-sticky offset-top="2.75rem">
      <div class="tab-box">
        <span class="is-active">{{ $t('userCenter.effectiveTicket') }}</span>
        <span @click="invalidCupones">{{ $t('userCenter.uneffectiveTicket') }}</span>
      </div>
    </van-sticky>
    <cupones-list ref="cuponesList" class="cupones-list" :ticket-list="dataList" />
  </base-template>
</template>

<script>
import CuponesList from '../components/cuponesList'
import { getMyTicket } from '@/api/axios/index'

import { moneyDivision } from '@/utils/index'
export default {
  components: { CuponesList },
  data() {
    return {
      moneyDivision,
      noticeText: '',
      lessPrice: 0,
      dataList: [],
      isEmpty: false,
      isActive: '1',
      emptyImage: require('@/assets/images/userCenter/no-cupones-icon.png')
    }
  },
  created() {
    this.getMyTicket()
  },
  methods: {
    // 查看失效的券
    invalidCupones() {
      this.$router.push({ path: '/invalidCupones' })
    },

    // 使用规则
    goRule() {
      this.$router.push({ path: '/useRule' })
    },

    // 获取券列表
    async getMyTicket() {
      const { data } = await getMyTicket({ ticket_type: 1 }) || {}
      this.dataList = data || []
      this.isEmpty = !this.dataList.length
      if (!this.isEmpty) {
        this.lessPrice = this.dataList[0].cumulative_money || 0
        this.lessPrice = this.dataList.reduce((accumulator, cur) => accumulator + (cur.use_coupon_amount * 1), 0)
        this.noticeText = this.dataList[0].num_msg || ''
      }
    }
  }
}
</script>
<style lang="scss" scoped>
:deep(.is-content){
  padding-left:0;
  padding-right:0;
}
.tips-btn{
  position: relative;
  text-align: right;
  font-size: 26px;
  font-family: Roboto-Regular, Roboto;
  font-weight: 400;
  color: #999999;
  line-height: 30px;
  margin-top: 20px;
  padding-right: 62px;
  span{
  &::after{
      content: '';
      position: absolute;
      width: 22px;
      height: 22px;
      right: 30px;
      top: 50%;
      margin-top: -11px;
      background: url('~@/assets/images/public/tips-icon.png') no-repeat center center;
      background-size: 100%;
    }
  }
}

:deep(.el-empty__image img){
  width: 322px;
  height: 322px;
}

:deep(.empty__description){
  font-size: 32px;
  font-weight: 500;
  color: #858597;
  line-height: 38px;
  margin-top: 0;
}
:deep(.el-empty){
  padding-top: 360px;
}

.top-area{
  position: relative;
  height: 258px;
  background: #3D5CFFFF;
  padding-top: 32px;
  .notice-box{
    height: 80px;
    line-height: 80px;
    color: #fff;
    background: #ffffff26;
    position: relative;
    padding-left: 78px;
    font-size: 24px;
    font-family: Roboto-Medium, Roboto;
    font-weight: 500;
    margin-bottom: 46px;
    &::before{
      content: '';
      width: 82px;
      height: 100%;
      position: absolute;
      left: 0;
      z-index: 1;
      background: url('~@/assets/images/userCenter/ticket-notice-icon.png') no-repeat 40px center #5776ff;
      background-size: 32px;
    }
  }
}
.has-notice-text{
  margin-bottom: 132px;
}

.remark-box{
  height: 192px;
  background: #FFFFFF;
  box-shadow: 0px 16px 24px -8px rgba(184,184,210,0.2);
  border-radius: 24px;
  position: absolute;
  left: 40px;
  right: 40px;
  padding: 34px 31px;
  .remark-top{
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    .remark{
      font-size: 24px;
      font-family: Roboto-Black, Roboto;
      font-weight: 900;
      color: #858597;
      line-height: 40px;
    }
    .use-rule{
      font-size: 24px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #3BB44B;
      line-height: 40px;
    }
  }
  .price{
    font-size: 40px;
    font-family: Roboto-Bold, Roboto;
    font-weight: bold;
    color: #1F1F39;
    line-height: 40px;
    position: relative;
    &::after{
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      height: 12px;
      bottom: -34px;
      background: url('~@/assets/images/userCenter/process-icon.png') no-repeat center center;
      background-size: 100%;
    }
  }
}

.tab-box{
  display: flex;
  height: 96px;
  margin-bottom: 30px;
  background: #F3F4F5FF;
  span{
    flex: 1;
    line-height: 96px;
    text-align: center;
    font-size: 28px;
    font-family: Roboto-Medium, Roboto;
    font-weight: 500;
    color: rgba(0,0,0,0.4);
  }
  span.is-active{
    font-size: 28px;
    font-family: Roboto-Medium, Roboto;
    font-weight: 500;
    color: rgba(0,0,0,0.8);
    position: relative;
    &::after{
      content: '';
      position: absolute;
      height: 6px;
      background: #21283DFF;
      width: 60px;
      bottom: 0;
      left: 50%;
      margin-left: -30px;
    }
  }
}

.cupones-list{
  padding: 0 40px;
}
.no-text{
  visibility: hidden;
}
</style>
